jQuery(function() {

    // country and location
    // todo - function toggleDisabledLocation from organisation_form.js belongs here

    if ($('#id_country').length != 0) {
        var countryId = $('#id_country').val();
        var countryOption = new Option(gettext("Search & Select Country..."),"", true, true);
        if (!countryId) {
            $('#id_country').prepend(countryOption);
        }
    
        $("#id_country").change(function() {
            var countryId = $(this).val();
            if (!countryId) {
                $("#id_location").prop("disabled", true);
                $('#id_location').val($('#id_location option:eq(0)').val()).trigger('change');
            } else {
                $.ajax({
                    url: "/users/ajax/loadlocation/",
                    type: "GET",
                    data: {
                        "country": countryId
                    }
                }).done(function(data) {
                    $('#id_location').empty().trigger('change');
                    var newOption = new Option(gettext("Search & Select Location..."),"", true, true);
                    $('#id_location').append(newOption).trigger('change');
                    $(data.locations).each(function(index, country) {
                        $('#id_location').append(country).trigger('change');
                    })
                    $('#id_location').val($('#id_location option:eq(0)').val()).trigger('change');
                    $("#id_location").prop("disabled", false);
                });
            }
        });
    }

    // domain
    // todo remove .delete-domain when waffle switch users:domain_as_crispy_field goes live
    $('.invalid-feedback').each(function() {
        if ($(this).text().length > 30) {
            $(this).parent().children().find('.delete-domain').css('top', '-83px');
        } else {
            $(this).parent().children().find('.delete-domain').css('top', '-59px');
        }
    });
    // disconnect from organisation
    $('.org-disconnect-dialog').on('click tap', function (e) {
        $('.orgDisconnectConfirm').removeClass('d-none');
	});

    $('.dismiss-org-modal').on('click tap', function (e) {
        $('.orgDisconnectConfirm').addClass('d-none');
	});
    // invites
    $('.modal-send-invite').on('click tap', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $('.invitee-form-invalid-feedback').remove();
        $('.is-invalid').removeClass('is-invalid');
        var targetSelect = $(this).closest('div').parent('div').find('.set-select').attr('js-target-select');

		$.ajax({
            url: $(this).attr('action'),
            type: "POST",
            data: {
                'email' : $('#id_email').val(),
                'first_name':  $('#id_first_name').val(),
                'last_name':  $('#id_last_name').val(),
                'subject': $('.subject').first().text(),
                'product': $('#id_product').attr('value'),
                'assignment_object': $('#id_assignment_object').attr('value'),
                'csrfmiddlewaretoken': $("input[name='csrfmiddlewaretoken']").val()
            }
        }).done(function(data) {
            if (data.error) {
                $(data.items.email).each(function(inx, obj) {
                    $('#id_email').addClass('is-invalid');
                    $('#id_email').after(`<span class="invitee-form-invalid-feedback invalid-feedback">${obj}</span>`)
                });
                $(data.items.first_name).each(function(inx, obj) {
                    $('#id_first_name').addClass('is-invalid');
                    $('#id_first_name').after(`<span class="invitee-form-invalid-feedback invalid-feedback"><strong>${obj}</span>`)
                });
                $(data.items.last_name).each(function(inx, obj) {
                    $('#id_last_name').addClass('is-invalid');
                    $('#id_last_name').after(`<span class="invitee-form-invalid-feedback invalid-feedback">${obj}</span>`)
                });
            } else {
                var newOption = new Option(data.obj_name, data.obj_id, true, true);
                $('#' + targetSelect).append(newOption).trigger('change');
                $('#invite-user-model').modal('hide');
                toastr.success(data.success);
            }
        });
    });

    $('.invite-link').on('click tap', function (e) {
        e.preventDefault();
		e.stopPropagation();
        $('.invitee-form-invalid-feedback').remove();
        $('.is-invalid').removeClass('is-invalid');
        var modal = $(this).attr('data-bs-target');
        var expression = $(this).attr('data-subject');
        // users should not see the name of the role with underscores
        expression = expression.replace('_', ' ');
        // gettext("%(count)s / %(total)s")

        const invite_txt = interpolate(gettext('Invite %(expression)s'), {expression: expression}, true);
        const first_name_placeholder = interpolate(gettext('Enter the %(expression)s\'s first name'), {expression: expression}, true);
        const last_name_placeholder = interpolate(gettext('Enter the %(expression)s\'s last name'), {expression: expression}, true);
        const email_name_placeholder = interpolate(gettext('Enter the %(expression)s\'s email address'), {expression: expression}, true);
        const send_txt = interpolate(gettext('Send %(expression)s invite'), {expression: expression}, true);

        $(modal).modal({show:true});
        $(modal).find('.modal-title').text(invite_txt);
        $(modal).find('.subject').text(send_txt);
        $(modal).find($('#id_first_name')).attr('placeholder', first_name_placeholder).val('');
        $(modal).find($('#id_last_name')).attr('placeholder', last_name_placeholder).val('');
        $(modal).find($('#id_email')).attr('placeholder', email_name_placeholder).val('');
        $(modal).find($('.set-select')).attr('js-target-select', $(this).closest("div").find("select").attr('id'));
	});
});
