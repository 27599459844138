global.bootstrap = require("bootstrap");
global.jQuery = require("jquery");
global.$ = require("jquery");
require("jquery-ui/dist/jquery-ui");
global.toastr = require("toastr");

require("../../static/vendor/js/formset");
require("./sidebar.js");
const site = require("./site");
require("../../../../anthesis-users/users/static/js/users");
