export default class SideBar {
    sidebarMenu = null;
    toggleSidebar = null;
    toggleMobileSidebar = null;
    currentApp = null;
    currentAppMenuItem = null;
    body = null;

    constructor() {
        this.body = $('body');
        this.sidebarMenu = $('#sidebar-menu');
        this.toggleSidebar = $('#toggle-sidebar');
        this.toggleMobileSidebar = $('#mobile-toggle-sidebar');
        this.currentApp = $('.current-app', this.sidebarMenu);
        this.currentAppMenuItem = $('.current-app-menu-item');
        this.listenEvents();
        this.refreshElementsBasedOnClosedStatus();
    }

    listenEvents() {
        this.toggleSidebar.on('click', this.onToggleSidebar.bind(this));
        this.toggleMobileSidebar.on('click', this.onToggleMobileSidebar.bind(this));
    }

    refreshElementsBasedOnClosedStatus() {
        const isClosed = this.isClosed();
        this.toggleSidebar.toggleClass('fa-rotate-180', isClosed);
        this.body.toggleClass('sidebar-closed', isClosed);
        this.refreshAppHighlighted(isClosed);
    }

    refreshAppHighlighted(isClosed) {
        // When sidebar is closed, app menus are not visible
        // and we wish to highlight app instead of app menu
        if (this.currentApp.length == 0 || this.currentAppMenuItem.length == 0) {
            return;
        }
        $('a', this.currentApp).toggleClass('active', isClosed);
        $('a', this.currentAppMenuItem).toggleClass('active', !isClosed);
    }

    onToggleSidebar() {
        // Event handler
        this.toggleSideBaseCloseStatus();
        this.refreshElementsBasedOnClosedStatus();
        this.sendClosedStatusToSession();
    }

    onToggleMobileSidebar() {
        // Event handler
        $('body').toggleClass('menu-overlay');
    }

    toggleSideBaseCloseStatus() {
        const isClosed = this.isClosed();
        this.sidebarMenu.toggleClass('closed', !isClosed);
    }

    sendClosedStatusToSession() {
        // Send sidebar close status to server to be stored on user session
        const isClosed = this.isClosed();
        const csrftoken = $('[name=csrfmiddlewaretoken]').val();
        const url = this.sidebarMenu.attr('url');
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken
            },
            body: JSON.stringify({ is_closed: isClosed}),
        })
        .then()
        .catch((error) => {
            toastr.error(error);
        });        
    }

    isClosed() {
        // Is sidebar closed ?
        // SidebarMenu is playing the role of source of truth
        return this.sidebarMenu.hasClass('closed');
    }



}